/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* rich-content */
.rich-content {
  width: 100%;
}

/* rc-block */
.rc-block {
  margin-bottom: 1.5rem;
}

.rc-block--callout {
  padding: 1.5rem;
  background-color: #efefef;
}

/* rc-cta */
.rc-cta {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.rc-cta img {
  width: 100%;
}

/* article-image-left */
/* article-image-right */
/* rc-two-cols */
/* rc-three-cols */
/* rc-image-list */
.rc-image-list img {
  width: 100%;
}

/* rc-image-slider */
.rc-image-slider__slider {
  margin: 0 auto;
  max-width: 100%;
}

/* rc-one-image */
.rc-one-image {
  width: 100%;
}

.rc-one-image img {
  margin: 0 auto;
}

/* rc-two-images */
.rc-two-images img {
  margin: 0 auto;
}

/* rc-three-images */
.rc-three-images img {
  margin: 0 auto;
}

/* rc-video */
.rc-video .video,
.rc-video-left .video,
.rc-video-right .video {
  position: relative;
  width: 100%;
  max-width: 854px;
  margin: 0 auto;
}

.rc-video .thumb,
.rc-video-left .thumb,
.rc-video-right .thumb {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.rc-video .thumb img,
.rc-video-left .thumb img,
.rc-video-right .thumb img {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
}

.rc-video .thumb::before,
.rc-video-left .thumb::before,
.rc-video-right .thumb::before {
  z-index: 1;
  content: '';
  display: block;
  width: 20%;
  padding-bottom: 20%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../../img/rc/rc-play.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}

/* rc-issuu */
.rc-issuu {
  width: 100%;
  background-color: #000;
  text-align: center;
  margin-bottom: 1.5rem;
}

.rc-issuu .issuuembed.issuu-isrendered {
  width: 100% !important;
}

/* rc-attachments */
.rc-attachments span {
  font-size: 90%;
}

/* rc-quote */
/* rc-btn */
/* rc-form */
.rc-form {
  margin: 0 0 1.5rem;
}

.rc-form .file-upload input[type="file"] {
  display: none;
}

/* rc-gmap */
.rc-gmap {
  margin-bottom: 1.5rem;
}

.rc-gmap .map {
  width: 100%;
  height: 350px;
}

/* rc-sounds */
.rc-sounds {
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  list-style: none;
}

.rc-sounds li {
  margin: 0 0 10px 0;
}

.rc-sounds li audio {
  max-width: 100%;
}

/* rc-tweet */
.rc-tweet iframe {
  margin-left: auto !important;
  margin-right: auto !important;
}

.rc-tweet .twitter-tweet {
  width: auto !important;
  max-width: 100%;
}

/* rc-insta */
.rc-insta .instagram-media {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* rc-vine */
.rc-vine {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.rc-vine iframe {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* rc-facebook */
.rc-facebook {
  margin-bottom: 30px;
  text-align: center;
}

.rc-facebook .fb_iframe_widget {
  max-width: 100%;
}

.rc-facebook .fb_iframe_widget span {
  max-width: 100%;
  overflow: hidden;
}

/* social share */
.social-share {
  margin: 10px 0;
}

.social-share ul:after {
  content: '';
  display: block;
  clear: both;
}

.social-share ul {
  margin: 0;
  padding: 0;
}

.social-share ul li {
  float: left;
  margin: 0 5px;
  list-style-type: none;
}

.social-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 0.6;
}

.social-share ul li.facebook a {
  background: url(../img/social-share/facebook.png);
  background-size: 30px 30px;
}

.social-share ul li.twitter a {
  background: url(../img/social-share/twitter.png);
  background-size: 30px 30px;
}

.social-share ul li.linkedin a {
  background: url(../img/social-share/linkedin.png);
  background-size: 30px 30px;
}

.social-share ul li.google-plus a {
  background: url(../img/social-share/googleplus.png);
  background-size: 30px 30px;
}

.social-share ul li a:hover {
  opacity: 1;
}

@media (min-width: 34em) {
  .rc-cta {
    float: left;
    width: 47%;
    margin-left: 0;
    margin-right: 3%;
  }
}

@media (min-width: 48em) {
  .rc-cta {
    width: 31.33%;
    margin-right: 2%;
  }
}

/*
 *	CONTENT BLOCK OVERRIDES
 */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
.rich-content p {
  line-height: 2;
}

.rich-content::after {
  content: '';
  display: table;
  clear: both;
}

.rc-rte h1,
.rc-rte h2,
.rc-rte h3,
.rc-rte h4,
.rc-rte h5,
.rc-rte h6,
.rich-content h1,
.rich-content h2,
.rich-content h3,
.rich-content h4,
.rich-content h5,
.rich-content h6 {
  margin-bottom: 1rem;
}

.rc-rte h1,
.rich-content h1 {
  font-size: 2rem;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.rc-rte h2,
.rich-content h2 {
  color: #f38f0a;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-size: 2.3rem;
  font-weight: 300;
  line-height: 1.1;
  text-transform: uppercase;
  margin-top: 2.4rem;
}

.rc-rte h3,
.rich-content h3 {
  color: #e6007e;
  font-size: 1.4rem;
}

.rc-rte h4,
.rich-content h4 {
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
}

.rc-rte h5,
.rich-content h5 {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.8rem;
}

.rc-rte h6,
.rich-content h6 {
  font-weight: bold;
  font-size: .9rem;
  text-transform: normal;
  color: #f38f0a;
  line-height: 1.8rem;
}

.rc-video .thumb::after,
.rc-video-left .thumb::after,
.rc-video-right .thumb::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(rgba(230, 0, 126, 0.7), rgba(243, 143, 10, 0.7));
}

.rc-quote {
  text-align: center;
}

/* rc-cta */
.rc-cta {
  width: 48%;
  max-width: 800px;
  margin-bottom: 30px;
}

.rc-cta .card {
  min-height: 230px;
}

@media (max-width: 767px) {
  .rc-cta {
    width: 100%;
    float: none;
  }
  .rc-cta .card {
    min-height: 0;
  }
}

.rc-video--wide .thumb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rc-video--wide .thumb::before {
  content: none;
}

.rc-video--wide .thumb > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.rc-video--wide .thumb__desc {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  font-weight: bold;
  line-height: 1;
}

.rc-video--wide p {
  margin-bottom: 0;
}

.rc-video--wide .text {
  position: absolute;
  right: 50%;
  top: 50%;
  width: 50%;
  padding: 20px 75px 20px 20px;
  transform: translateY(-50%);
  text-align: right;
}

.rc-video--wide .title {
  font-size: 46px;
  line-height: 1;
  text-transform: uppercase;
}

.rc-video--wide .subtitle {
  font-size: 32px;
}

.rc-video--wide .play {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  padding: 20px 20px 20px 75px;
  transform: translateY(-50%);
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
}

.rc-video--wide .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90px !important;
  height: auto;
  margin-left: -45px;
  margin-top: -51px;
}

.rc-video--wide .video {
  width: 100%;
  max-width: 100%;
}

/* nieuwe contentblok: contact links */
.cta-left {
  padding: 20px;
  text-align: center;
  background: #ffffff;
  border: 2px solid transparent;
  -o-border-image: linear-gradient(to top, #f38f0a 48%, #e6007e 95%);
  border-image: linear-gradient(to top, #f38f0a 48%, #e6007e 95%);
  border-image-slice: 1;
}

.cta-left h4 {
  color: #e6007e;
  font-weight: normal;
}

/* nieuwe contentblok */
.rc-quote-text .share {
  display: block;
  text-align: right;
  font-size: 0;
  text-align: center;
}

.rc-quote-text .share li {
  display: inline-block;
  font-size: 1.2rem;
}

.rc-quote-text .share a {
  color: #444444;
}

.rc-quote-text .share a:hover, .rc-quote-text .share a:focus {
  color: #f38f0a;
}

.rc-quote-text .share a:active {
  color: #e6007e;
}

/* forms */
.rc-form label {
  text-transform: uppercase;
  font-size: .75rem;
}

@charset "UTF-8";
/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite.png");
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("/front/resources/plugins/fancybox/img/fancybox_loading.gif") center center no-repeat;
}

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("/front/resources/plugins/fancybox/img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("/front/resources/plugins/fancybox/img/fancybox_overlay.png");
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
  #fancybox-loading div {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
}

/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/*
 *	BOOSTRAP OVERRIDES
 */
/* text */
a {
  color: #e6007e;
}

a:hover, a:focus {
  color: #f38f0a;
}

a:active {
  color: #e6007e;
}

button, input, optgroup, select, textarea {
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  font-size: .9rem;
}

/* navbar */
.navbar-toggleable-md .navbar-collapse {
  width: auto;
  margin-left: auto;
}

.navbar {
  padding: 5px 0;
}

.navbar-brand {
  width: 200px;
}

.navbar-nav {
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.1;
  letter-spacing: .05rem;
  text-transform: uppercase;
}

.navbar-nav .nav-item + .nav-item {
  margin-left: 7px;
}

.navbar-nav .nav-link {
  padding: 0 1em;
  color: #444444;
  transition: color .1s ease-out;
}

.navbar-nav .nav-link:hover {
  color: #e6007e;
}

.navbar-nav .nav-link:active {
  color: #e6007e;
}

.navbar-nav .active .nav-link {
  color: #f38f0a;
}

.dropdown-menu {
  margin-top: 7px;
  padding: 0;
  border-radius: 0;
}

.dropdown-menu .nav-item + .nav-item {
  margin-left: 0;
  margin-top: 10px;
}

.dropdown-item {
  padding: 8px .5rem;
  font-weight: bold;
  font-size: 1rem;
  transition: color .15s ease-out;
}

.dropdown-item:hover {
  color: #e6007e;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: transparent;
  color: #f38f0a;
}

.dropdown-toggle::after {
  margin-top: -5px;
  opacity: .5;
}

.navbar-toggler {
  text-transform: uppercase;
  font-size: .9rem;
  border: 2px solid #444444;
  border-radius: 8px;
  color: #444444;
  height: 40px;
  line-height: 1;
  cursor: pointer;
  transition: border .15s ease-out, color .15s ease-out;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  color: #f38f0a;
  border-color: #f38f0a;
}

.navbar-toggler:active {
  color: #e6007e;
  border-color: #e6007e;
}

.navbar-toggler[aria-expanded="true"] {
  color: #696969;
  border-color: #696969;
}

/* buttons */
.btn {
  border-radius: 8px;
  border-width: 2px;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 1.4rem;
  cursor: pointer;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  white-space: normal;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: none;
  box-shadow: none;
}

.btn-outline-primary {
  color: #e6007e;
  border-color: #e6007e;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */
}

.btn-outline-primary:focus, .btn-outline-primary.focus, .btn-outline-primary:active:focus, .btn-outline-primary:active.focus, .btn-outline-primary.active:focus, .btn-outline-primary.active.focus {
  outline: none;
}

.btn-outline-primary:hover, .btn-outline-primary.hover, .btn-outline-primary:focus {
  color: #f38f0a;
  border-color: #f38f0a;
  background-color: transparent;
}

.btn-outline-primary:active, .btn-outline-primary.active {
  opacity: .8;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #fff;
  border-color: #fff;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus, .btn-outline-secondary:active:focus, .btn-outline-secondary:active.focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.active.focus {
  outline: none;
}

.btn-outline-secondary:hover, .btn-outline-secondary.hover, .btn-outline-secondary:focus {
  color: #fff;
  border-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
}

.btn-outline-secondary:active, .btn-outline-secondary.active {
  opacity: .9;
  background-color: transparent;
}

.btn-primary {
  color: #e6007e;
  border-color: #e6007e;
  background-color: transparent;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */
}

.btn-primary:focus, .btn-primary.focus, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:focus, .btn-primary.active.focus {
  outline: none;
}

.btn-primary:hover, .btn-primary.hover, .btn-primary:focus {
  color: #f38f0a;
  border-color: #f38f0a;
  background-color: transparent;
}

.btn-primary:active, .btn-primary.active {
  color: #e6007e;
  border-color: #e6007e;
  background-color: transparent;
  opacity: .8;
}

.btn-primary.disabled, .btn-primary[disabled],
fieldset[disabled] .btn-primary {
  color: #696969;
  border-color: #696969;
  background-color: transparent;
}

.btn-secondary {
  color: #696969;
  border-color: #a2a2a2;
  background-color: transparent;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */
}

.btn-secondary:focus, .btn-secondary.focus, .btn-secondary:active:focus, .btn-secondary:active.focus, .btn-secondary.active:focus, .btn-secondary.active.focus {
  outline: none;
}

.btn-secondary:hover, .btn-secondary.hover, .btn-secondary:focus {
  background-color: transparent;
  color: #f38f0a;
  border-color: #f38f0a;
}

.btn-secondary:active, .btn-secondary.active {
  background-color: transparent;
  color: #e6007e;
  border-color: #e6007e;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: .1rem .5rem;
  font-size: 1.2rem;
}

/* forms */
/* breadcrumb */
.breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 1rem;
}

.breadcrumb a {
  color: #444444;
  text-decoration: underline;
}

.breadcrumb a:hover, .breadcrumb a:focus {
  color: #f38f0a;
}

.breadcrumb a:active {
  color: #e6007e;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #444444;
}

.breadcrumb-item.active {
  color: #444444;
}

/* cards */
.card {
  border-radius: 0;
}

/* form controls */
.form-control {
  border-radius: 0;
}

.form-control:focus {
  border-color: #f38f0a;
}

.has-danger .form-control {
  border-color: #e6007e;
}

/* carousel */
.carousel-control-next,
.carousel-control-prev {
  opacity: .7;
}

.carousel-control-next:focus,
.carousel-control-prev:focus {
  opacity: .7;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
  opacity: 1;
}

/*
 *	QUOTE
 */
.blockquote {
  padding: 0;
  border-left: none;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  font-weight: 900;
  text-transform: uppercase;
}

.blockquote p {
  line-height: 1.1;
  padding-bottom: 5px;
  border-bottom: 1px dashed #696969;
}

.blockquote p::before, .blockquote p::after {
  font-size: 2.4rem;
  margin: 0 3px;
}

.blockquote p::before {
  content: '“';
}

.blockquote p::after {
  content: '”';
}

.blockquote footer {
  text-transform: none;
  font-size: 1.2rem;
  font-weight: normal;
}

/* box sizing */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box;
}

/* hide placeholder text on focus */
input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

/* standard */
html {
  overflow-y: scroll;
  height: 100%;
}

body {
  min-height: 100%;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  font-size: .9rem;
  padding-top: 60px;
  color: #444444;
}

/*
 *	UTILITIES
 */
.supercontainer {
  margin: 0 auto;
  padding: 0 15px;
  width: 1560px;
  max-width: 100%;
  overflow: hidden;
  /* voor banner overlay */
}

.left-pad {
  padding-left: 175px;
}

.right-pad {
  padding-right: 175px;
}

.flex-stretch {
  display: flex;
  flex-direction: column;
}

.flex-stretch > * {
  flex: 1 1 auto;
  min-height: 2px;
}

.pos-static {
  position: static;
}

/*
 *	GENERAL
 */
.gradient-title {
  position: relative;
  min-height: 100px;
  margin-bottom: 20px;
  padding-left: 40px;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

.gradient-title .super {
  font-size: 1.1rem;
  line-height: 1;
  font-weight: 300;
}

.gradient-title .title {
  margin-bottom: 1.3rem;
  font-size: 2rem;
}

.gradient-title .sub {
  font-size: 2.3rem;
  line-height: 1.1;
  color: #f38f0a;
  font-weight: 300;
}

.gradient-title .button-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.gradient-title .button-wrapper .sub {
  flex: 0 1 auto;
  margin-right: 15px;
}

.gradient-title .button {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.gradient-title .button::before {
  flex: 1 1 auto;
  content: '';
  display: block;
  height: 1px;
  background-color: #9e9e9e;
}

.gradient-title .button a {
  flex: 0 1 auto;
  display: inline-block;
  padding: .5rem .5rem;
  line-height: 1;
  color: #fff;
  text-decoration: none;
  border: none;
  background-color: #e6007e;
  border-radius: 5px;
  text-transform: none;
  font-size: 1.2rem;
  font-weight: 300;
  transition: color .15s ease-out, background .15s ease-out, border .15s ease-out;
}

.gradient-title .button a:hover, .gradient-title .button a:focus {
  color: #fff;
  background-color: #f38f0a;
}

.gradient-title .button a:active {
  color: #fff;
  border-color: #f38f0a;
}

.gradient-title::before {
  content: '';
  display: block;
  width: 2px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #e6007e;
  background-image: linear-gradient(#e6007e, #f38f0a);
}

.gradient-title--small::before {
  height: 40px;
}

.gradient-title--boring {
  padding-left: 0;
}

.gradient-title--boring::before {
  content: none;
}

.gradient-title--button .super {
  margin-bottom: 30px;
}

.gradient-title--button .title,
.gradient-title--button .sub {
  margin-bottom: 0;
}

.gradient-title--button .caps-title {
  font-size: 1.4rem;
}

.caps-title {
  text-transform: uppercase;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 1.1rem;
}

/* sections */
.section {
  padding: 80px 0;
}

.section--shadow {
  position: relative;
  background-color: #f1f1f1;
}

.section--shadow::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.15) 85%, rgba(0, 0, 0, 0) 100%);
}

.bg-gray + .section--shadow {
  background-color: #fff;
}

/* backgrounds */
.bg-gray {
  background-color: #f3f3f3;
}

.bg-darkgray {
  background-color: #e3e3e4;
}

.link-list {
  margin: 0 0 1rem;
  padding: 0;
  list-style: none;
}

.link-list li {
  margin-bottom: .4rem;
}

.link-list a {
  color: #444444;
  text-decoration: underline;
}

.link-list a:hover, .link-list a:focus {
  color: #f38f0a;
}

.link-list a:active {
  color: #e6007e;
}

.tag-list {
  list-style: none;
  margin: 0 0 1rem;
  padding: 0;
  font-size: 0;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  text-transform: none;
}

.tag-list li {
  display: inline-block;
  font-size: .9rem;
}

.tag-list li + li::before {
  content: '|';
  margin: 0 10px;
}

.tag-list a {
  text-decoration: underline;
  color: #444444;
}

.tag-list a:hover, .tag-list a:focus {
  color: #f38f0a;
}

.tag-list a:active {
  color: #e6007e;
}

.share {
  padding: 0;
  list-style: none;
  font-size: 1.2rem;
}

.share li {
  display: inline-block;
}

.share a {
  color: #444444;
}

.text-light {
  color: #696969;
  font-size: .7rem;
}

/* rc marges */
.rich-content {
  padding: 0 40px 0 175px;
}

.rich-content--home {
  padding-left: 40px;
}

.rich-content--np {
  padding-left: 40px;
}

.button-list {
  margin-top: 60px;
  padding: 0 40px 0;
}

.button-list a, .button-list button {
  margin: 0 10px 10px 0;
}

/* form stuff */
.with-errors {
  color: #e6007e;
}

.form-lines .form-control {
  padding-left: 0;
  padding-right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.form-lines label {
  margin-bottom: .25rem;
}

/*
 *	HEADER
 */
.header {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  transition: padding .15s ease-out;
}

.header .supercontainer {
  overflow: visible;
  /* subnav fix */
  position: relative;
  /* searchform */
}

.header::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right, #f38f0a, #e6007e);
  pointer-events: none;
  opacity: 0;
  transition: opacity .15s ease-out;
}

.header--border::after {
  opacity: 1;
}

.lang {
  display: block;
  display: inline-flex;
  margin: 0 0 0 30px;
  padding: 0;
  font-size: 0;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-weight: 900;
}

.lang li {
  display: inline-block;
  font-size: 1rem;
}

.lang li + li {
  margin-left: .5rem;
}

.lang a {
  color: #444444;
  text-decoration: none;
}

.lang a:hover, .lang a:focus {
  color: #e6007e;
  text-decoration: none;
}

.lang .active a {
  color: #f38f0a;
}

.search-toggler {
  border: none;
  background: transparent;
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin-left: 25px;
  padding: 0;
  border: 2px solid #696969;
  border-radius: 50%;
  color: #696969;
  opacity: .5;
  line-height: 1;
  font-size: 1rem;
  transition: color .15s ease-out, border .15s ease-out;
  text-align: center;
}

.search-toggler:hover, .search-toggler:focus {
  opacity: .8;
  border-color: #f38f0a;
  color: #f38f0a;
}

.search-toggler:active {
  border-color: #e6007e;
  color: #e6007e;
}

.search-form {
  position: absolute;
  top: 100%;
  right: 10px;
  margin-top: 2px;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  font-size: 0;
  padding: 5px;
  display: none;
  border: 2px solid rgba(105, 105, 105, 0.3);
  border-top: none;
}

.search-form input,
.search-form button {
  height: 35px;
  border: none;
}

.search-form input {
  padding: 0 5px;
}

.search-form button {
  width: 35px;
  height: 35px;
  margin-left: 5px;
  padding: 0;
  cursor: pointer;
  color: #696969;
  border: 2px solid #696969;
  border-radius: 50%;
  background-color: transparent;
  font-size: 1rem;
}

.search-form button:hover, .search-form button:focus {
  color: #f38f0a;
  border-color: #f38f0a;
}

.search-open .search-form {
  display: block;
}

/*
 *	BANNER
 */
.banner {
  position: relative;
  padding-bottom: 15px;
}

.banner img {
  width: 100%;
}

.banner .container {
  position: relative;
}

.banner__overlay {
  z-index: 1;
  position: absolute;
  right: 15px;
  bottom: 0;
  left: 0;
  max-width: 100%;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
}

.banner__overlay .container {
  z-index: 1;
  position: relative;
  /*padding: 40px 40px 0;*/
  padding: 0;
  color: #fff;
}

.banner__overlay .container::after {
  z-index: -1;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  width: calc(100% + 220px);
  background-image: linear-gradient(to right, rgba(243, 143, 10, 0.7) 15%, rgba(230, 0, 126, 0.7) 85%);
}

.banner__overlay .slider-quote {
  padding-top: 40px;
}

.banner__overlay .title {
  margin-bottom: 0;
  font-weight: 900;
  font-size: 2.6rem;
  line-height: 1.1;
  letter-spacing: .08rem;
  text-align: center;
  padding-top: 2rem;
}

.banner__overlay .title p {
  margin-bottom: 0;
}

.banner__overlay .title--quote {
  position: relative;
  margin-bottom: 20px;
  /*&::before,
		&::after {
			font-size: 1.3em;
		}
		&::before {
			content: '“';
			vertical-align: super;
		}
		&::after {
			content: '”';
			vertical-align: sub;
		}*/
}

.banner__overlay .bordered {
  position: relative;
  border-right: 1px solid #fff;
  padding-bottom: 40px;
}

.banner__overlay .btn {
  margin: 0 5px 5px;
}

.banner__overlay--white .container {
  padding: 5px 10px 20px;
}

.banner__overlay--white .container::after {
  top: auto;
  height: 15px;
}

.banner__overlay--white .container::before {
  z-index: -1;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  width: calc(100% + 220px);
  background-color: rgba(255, 255, 255, 0.7);
}

/*
 *	CONTENT BANNER
 */
.content-banner {
  position: relative;
}

.content-banner img {
  width: 100%;
}

.content-banner__text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-banner__text .container {
  display: flex;
  justify-content: flex-end;
}

.content-banner__text .inner {
  width: 680px;
  max-width: 100%;
  padding: 60px 30px;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 1.3;
  text-align: center;
  color: #fff;
  background-image: linear-gradient(to right, rgba(243, 143, 10, 0.7), rgba(230, 0, 126, 0.7));
}

.content-banner__text p {
  margin-bottom: 0;
}

/*
 *	TETRIS
 */
.tetris-wrapper {
  display: flex;
  justify-content: space-between;
}

.tetris {
  position: relative;
}

.tetris__img {
  width: 176px;
  margin: 0 auto;
}

.tetris__img img {
  max-width: 100%;
}

.tetris__text {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 60%;
  width: 330px;
  background-color: #f0f0f0;
  padding: 10px 40px;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.7rem;
}

.tetris__text .title {
  margin-bottom: 0;
  font-size: 1.7rem;
  font-weight: 900;
}

.tetris__text a {
  color: #444444;
  text-decoration: underline;
}

.tetris__text a:hover, .tetris__text a:focus {
  color: #f38f0a;
}

.tetris__text a a:active {
  color: #e6007e;
}

.tetris--alt {
  text-align: right;
}

.tetris--alt .tetris__text {
  top: auto;
  left: auto;
  bottom: 0;
  right: 60%;
}

/*
 *	BLOG
 */
.blog {
  margin-bottom: 30px;
  border-bottom: 1px dotted #444444;
}

.blog a {
  display: block;
  width: 100%;
  min-height: 100%;
  position: relative;
  text-decoration: none;
  color: #444444;
  transition: color .15s ease-out;
}

.blog a::before {
  content: '';
  display: block;
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(rgba(230, 0, 126, 0.7), rgba(243, 143, 10, 0.7));
  transition: opacity .15s ease-out;
  pointer-events: none;
}

.blog a:hover, .blog a:focus {
  color: #fff;
}

.blog a:hover::before, .blog a:focus::before {
  opacity: 1;
}

.blog__img img {
  width: 100%;
}

.blog__text {
  z-index: 2;
  position: relative;
  padding: 20px;
}

.blog__text .title {
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
}

.blog__text .by {
  margin-bottom: 1rem;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
}

.blog__text .by span {
  text-decoration: underline;
}

.blog__text p {
  margin-bottom: 0;
  line-height: 2;
}

.blog--huge .blog__img::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.7) 100%);
  transition: opacity .15s ease-out;
}

.blog--huge .blog__text {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
}

.blog--huge a:hover .blog__img::after,
.blog--huge a:focus .blog__img::after {
  opacity: 0;
}

/*
 *	BLOG DETAIL
 */
.blog-nav {
  z-index: 1;
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-size: 0;
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
  text-align: right;
}

.blog-nav li {
  display: inline-block;
  margin: 0 10px;
  font-size: 1.1rem;
}

.blog-nav a {
  color: #444444;
}

.blog-nav a:hover, .blog-nav a:focus {
  color: #f38f0a;
}

.blog-nav a:active {
  color: #e6007e;
}

/*
 *	CONTACT BAR
 */
@keyframes bg-pulse {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 500% 0;
  }
}

.contact-bar {
  margin: 15px 0;
  padding: 25px 0;
  color: #fff;
  background-color: #e6007e;
  text-align: center;
  background-image: linear-gradient(to right, #e6007e 5%, #f38f0a 48%, #f38f0a 52%, #e6007e 95%);
  background-size: 500% auto;
  background-position: 0 0;
  animation: bg-pulse 40s infinite linear;
}

.contact-bar .title {
  font-size: 1.8rem;
  line-height: 1.1;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-weight: 900;
}

.contact-bar .title--quote::before {
  content: '“';
  margin-right: .2rem;
}

.contact-bar .title--quote::after {
  content: '”';
  margin-left: .1rem;
}

.contact-bar .btn {
  margin: 0 5px 5px;
}

.carousel--quotes .carousel-control-next,
.carousel--quotes .carousel-control-prev {
  width: 50px;
}

.carousel--quotes .carousel-item {
  justify-content: center;
  align-items: center;
}

.slider-quote {
  flex: 1 1 auto;
  width: 100%;
  padding: 0 50px;
}

/*
* REFERENTIES SLIDER
*/
.carousel--references .carousel-item {
  justify-content: center;
  align-items: center;
  padding: 0 6% !important;
}

.slider-reference {
  flex: 1 1 auto;
  width: 100%;
  padding: 0 50px;
}

.slider-reference .carousel-item {
  justify-content: center;
  align-items: center;
  padding: 0 6% !important;
}

.carousel-item {
  text-align: center;
}

.carousel-item .title {
  margin-bottom: 1.3rem;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
}

.carousel-item .sub {
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 300;
  color: #f38f0a;
  font-style: italic;
}

/*
 *	CASESTUDIES SLIDER
 */
.carousel--casestudies {
  margin: 60px 0;
}

.carousel--casestudies .row {
  flex: 1 1 auto;
}

.carousel--casestudies .case {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translateY(-50%);
}

.carousel--casestudies .case-col {
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel--casestudies .carousel-control-next,
.carousel--casestudies .carousel-control-prev {
  opacity: 1;
  color: #444444;
  font-size: 1.4rem;
  top: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  background-color: #fff;
}

.carousel--casestudies .carousel-control-next:hover,
.carousel--casestudies .carousel-control-prev:hover {
  color: #f38f0a;
}

.carousel--casestudies .carousel-control-next:active,
.carousel--casestudies .carousel-control-prev:active {
  color: #e6007e;
}

.carousel--casestudies .carousel-control-next {
  right: 50%;
}

.case {
  position: relative;
  max-width: 100%;
  padding: 30px 40px;
  background-color: #fff;
}

.case .title {
  width: 100%;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  color: #f38f0a;
  transition: color .15s ease-out;
}

.case a:active {
  color: #e6007e;
}

.case a:active .title {
  color: #e6007e;
}

.case .tags {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0 0 1rem;
  font-size: 0;
}

.case .tags li {
  display: inline-block;
  margin: 0;
  font-size: .9rem;
}

.case .tags li + li::before {
  content: '|';
  margin: 0 5px;
}

.case .tags a {
  color: #444444;
}

.case .tags a:hover, .case .tags a:focus {
  color: #f38f0a;
}

.case .tags a:active {
  color: #e6007e;
}

.case p {
  width: 100%;
  line-height: 2;
}

.case::before {
  content: '';
  display: block;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #f38f0a, #e6007e);
}

/*
 *	REFERENCES SLIDER
 */
.flexslider {
  margin-top: 20px;
  margin-bottom: 0;
  border: none;
  background-color: transparent;
}

.flex-direction-nav {
  opacity: 1 !important;
}

.flex-direction-nav a {
  opacity: 1;
  font-size: 2rem;
  color: #444444;
}

.flex-direction-nav a:focus {
  color: #444444;
}

.flex-direction-nav a:hover {
  color: #f38f0a;
}

.flex-direction-nav a::before {
  content: none !important;
}

.flex-direction-nav i, .flex-direction-nav i::before {
  line-height: 1;
}

.flex-direction-nav .flex-prev {
  line-height: 40px;
  left: -40px !important;
}

.flex-direction-nav .flex-next {
  line-height: 40px;
  right: -40px !important;
}

.logo-wrap {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 0;
  padding-bottom: 58%;
}

.logo-wrap__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-wrap__inner img {
  flex: 0 1 auto;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto;
}

/*
 *	PROFILE
 */
.profile {
  position: relative;
  margin: 0 auto 60px;
  text-align: center;
  width: 330px;
  max-width: 100%;
}

.profile a {
  position: relative;
  display: block;
  color: #444444;
  text-decoration: none;
}

.profile::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  left: 30px;
  background-color: #fff;
}

.profile__detail {
  margin: 0 0 0 -100px;
  width: auto;
  -ms-grid-row-align: center;
  align-self: center;
}

.profile__detail .profile__text {
  position: static;
  margin: 0;
  padding: 50px;
}

.profile__img img {
  width: 100%;
}

.profile__text {
  position: relative;
  z-index: 2;
  padding: 20px 20px 40px;
  margin: -40px 30px 0;
  background-color: #fff;
  text-align: center;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
  transition: color .15s ease-out, background .15s ease-out;
}

.profile__text .title {
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.profile__text .title, .profile__text p {
  position: relative;
  z-index: 2;
}

.profile__social {
  position: relative;
  z-index: 2;
  margin-top: -40px;
  padding: 0 30px;
}

.profile__social .social {
  display: inline-block;
  background-color: #fff;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 0;
}

.profile__social .social li {
  display: inline-block;
}

.profile__social .social a {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #444444;
  font-size: 1.1rem;
}

.profile__overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 30px;
  right: 30px;
  bottom: 0;
  background-image: linear-gradient(rgba(230, 0, 126, 0.7), rgba(243, 143, 10, 0.7));
  color: #fff;
  padding: 20px 20px;
  text-align: center;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 1.1;
  opacity: 0;
  transition: opacity .15s ease-out;
}

.profile__overlay .text {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.profile__overlay p {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.profile__overlay p span {
  display: inline-block;
  max-width: 100%;
}

.profile a:hover .profile__text,
.profile a:focus .profile__text {
  color: #fff;
  background-color: transparent;
}

.profile a:hover .profile__overlay,
.profile a:focus .profile__overlay {
  opacity: 1;
}

.profile-cta {
  padding: 60px 30px 30px;
  text-align: center;
}

.profile-cta .title {
  margin-bottom: 2rem;
  color: #f38f0a;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1.1;
  font-weight: 700;
}

/*
 *	GLOSSARIUM
 */
.glossarium-search p {
  font-size: 1.4rem;
}

.glossarium-search input {
  border-radius: 25px;
}

.glossarium {
  display: flex;
  padding: 25px 0;
  border-bottom: 1px dashed rgba(105, 105, 105, 0.5);
}

.glossarium__letter {
  flex: 0 1 50px;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: bold;
}

.glossarium__words {
  flex: 1 1 auto;
}

.glossarium__words .words {
  display: block;
  margin: 0;
  padding: 0;
}

.glossarium__words .words li {
  display: inline-block;
}

.glossarium__words .words li::before {
  content: '- ';
}

.glossarium__words .words a {
  color: #444444;
}

.glossarium__words .words a:hover, .glossarium__words .words a:focus {
  color: #f38f0a;
}

.glossarium__words .words a:active {
  color: #e6007e;
}

.glossarium__words .words .active a {
  color: #f38f0a;
  font-weight: bold;
  font-size: 1.1rem;
  text-decoration: none;
}

.glossarium__words .explanation {
  display: none;
  margin-top: 20px;
}

.glossarium__words .explanation p {
  margin-bottom: 0;
}

.glossarium__words .explanation.active {
  display: block;
}

/*
 *	PACK
 */
.pack {
  position: relative;
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.7) 30%);
  display: flex;
  flex-direction: column;
}

.pack__number {
  position: absolute;
  top: 10px;
  left: -20px;
  color: #f38f0a;
  font-size: 130px;
  line-height: 1;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.pack__desc {
  flex: 1 1 auto;
  padding: 20px 20px 40px 50px;
  display: flex;
  flex-direction: column;
}

.pack__desc .title {
  flex: 0 1 auto;
  width: 100%;
  margin-bottom: 1.5rem;
  color: #e6007e;
  font-size: 1.3rem;
}

.pack__desc p {
  width: 100%;
}

.pack__desc ul {
  width: 100%;
  flex: 0 1 auto;
  display: block;
  margin: 0 0 1.5rem;
  padding: 0;
  list-style: none;
}

.pack__desc li {
  padding: 3px 6px;
  border-bottom: 1px dashed #696969;
}

.pack__desc .btn-wrapper {
  width: 100%;
  flex: 0 1 auto;
  text-align: center;
  margin-top: auto;
}

.pack--1 .pack__number {
  left: -5px;
}

.pack--most-picked {
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.85) 30%);
}

.pack--most-picked::before {
  content: '';
  display: block;
  width: 110px;
  height: 62px;
  position: absolute;
  top: 5px;
  right: 10px;
  background-image: url("/img/meest-gekozen.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.pack--2 .pack__number {
  font-weight: 400;
}

.pack--3 .pack__number {
  font-weight: 300;
}

/*
 *	FOOTER
 */
.footer {
  color: #f2f2f2;
  background-color: #3c3c3b;
}

.footer__text {
  padding: 60px 0;
  line-height: 2;
}

.footer__text .title {
  text-transform: uppercase;
  font-family: "Exo 2 Condensed", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.1rem;
  color: #fff;
}

.footer__text .light {
  color: #a2a2a2;
}

.footer__text ul {
  padding: 0;
  list-style: none;
}

.footer__text a {
  color: #f2f2f2;
}

.footer__text a:hover, .footer__text a:focus {
  color: #f38f0a;
}

.footer__text a:active {
  color: #e6007e;
}

.footer__bar {
  height: 50px;
  background-color: #e6007e;
  background-image: linear-gradient(to right, #f38f0a, #e6007e);
  line-height: 50px;
}

.footer__bar a {
  color: #ffffff;
}

.subscribe {
  width: 100%;
}

.subscribe::after {
  content: '';
  display: table;
  clear: both;
}

.subscribe input[type="text"],
.subscribe input[type="email"] {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
}

.subscribe input[type="text"]:focus,
.subscribe input[type="email"]:focus {
  border-bottom-color: #fff;
}

.subscribe .btn {
  margin-top: 2rem;
}

.footer-social {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 0;
}

.footer-social li {
  display: inline-block;
  font-size: 1.5rem;
  margin: 0 10px 10px 0;
}

.footer-social a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  transition: background .15s ease-out;
}

.footer-social a:hover, .footer-social a:focus {
  color: #fff;
  background-color: #f38f0a;
}

.footer-social a:active {
  background-color: #e6007e;
}

.footer-social .facebook:hover, .footer-social .facebook:focus {
  background-color: #3b5998;
}

.footer-social .linkedin:hover, .footer-social .linkedin:focus {
  background-color: #007bb5;
}

.footer-social .twitter:hover, .footer-social .twitter:focus {
  background-color: #55acee;
}

.footer-social .instagram:hover, .footer-social .instagram:focus {
  background-color: #d93175;
}

.footer-social .youtube:hover, .footer-social .youtube:focus {
  background-color: #bb0000;
}

.footer-social .vimeo:hover, .footer-social .vimeo:focus {
  background-color: #aad450;
}

.footer-social .pinterest:hover, .footer-social .pinterest:focus {
  background-color: #cb2027;
}

/*
 *	IMPORTS
 */
/* Generated by Font Squirrel (https://www.fontsquirrel.com) on February 28, 2017 */
@font-face {
  font-family: 'Exo 2 Condensed';
  src: url("/font/exo2-blackcondensed.woff2") format("woff2"), url("/font/exo2-blackcondensed.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2 Condensed';
  src: url("/font/exo2-boldcondensed.woff2") format("woff2"), url("/font/exo2-boldcondensed.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2 Condensed';
  src: url("/font/exo2-lightcondensed.woff2") format("woff2"), url("/font/exo2-lightcondensed.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2 Condensed';
  src: url("/font/exo2-regularcondensed.woff2") format("woff2"), url("/font/exo2-regularcondensed.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'fontello';
  src: url("/font/fontello.eot?57595381");
  src: url("/font/fontello.eot?57595381#iefix") format("embedded-opentype"), url("/font/fontello.woff2?57595381") format("woff2"), url("/font/fontello.woff?57595381") format("woff"), url("/font/fontello.ttf?57595381") format("truetype"), url("/font/fontello.svg?57595381#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/font/fontello.svg?57595381#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-phone:before {
  content: '\e800';
}

/* '' */
.icon-mail:before {
  content: '\e801';
}

/* '' */
.icon-home:before {
  content: '\e802';
}

/* '' */
.icon-cancel:before {
  content: '\e803';
}

/* '' */
.icon-search:before {
  content: '\e804';
}

/* '' */
.icon-twitter:before {
  content: '\f099';
}

/* '' */
.icon-facebook:before {
  content: '\f09a';
}

/* '' */
.icon-menu:before {
  content: '\f0c9';
}

/* '' */
.icon-gplus:before {
  content: '\f0d5';
}

/* '' */
.icon-linkedin:before {
  content: '\f0e1';
}

/* '' */
.icon-angle-left:before {
  content: '\f104';
}

/* '' */
.icon-angle-right:before {
  content: '\f105';
}

/* '' */
.icon-angle-up:before {
  content: '\f106';
}

/* '' */
.icon-angle-down:before {
  content: '\f107';
}

/* '' */
.icon-youtube:before {
  content: '\f167';
}

/* '' */
.icon-instagram:before {
  content: '\f16d';
}

/* '' */
.icon-pinterest:before {
  content: '\f231';
}

/* '' */
.icon-vimeo:before {
  content: '\f27d';
}

/* '' */
/*
 *	WEBSHOP STANDAARD CSS
 */
/* 
 *	CART ITEM
 */
.ws-cart-item {
  overflow: hidden;
  display: flex;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 0;
}

.ws-cart-item:last-of-type {
  border-bottom: 1px solid #f0f0f0;
}

.ws-cart-item__img {
  flex: 1 0 17%;
  max-width: 17%;
}

.ws-cart-item__details {
  flex: 1 0 50%;
  max-width: 50%;
  padding-left: 1rem;
}

.ws-cart-item__actions {
  flex: 1 0 33%;
  max-width: 33%;
  padding-left: 1rem;
}

.ws-cart-item__actions .price {
  margin-bottom: 1rem;
}

.ws-cart-item__actions .price::after {
  content: '';
  display: table;
  clear: both;
}

.ws-cart-item__actions .amount {
  float: left;
}

.ws-cart-item__actions .amount input {
  display: inline-block;
  margin-left: .5rem;
  width: 60px;
}

.ws-cart-item__actions .remove {
  clear: both;
}

/*
*	CART LIST
*/
.ws-cart-dropdown {
  font-size: .8em;
  padding: 1rem;
}

.ws-cart-dropdown .ws-cart-list {
  max-width: 350px;
}

.ws-cart-dropdown .ws-cart-list .ws-cart-item p {
  margin-bottom: 5px;
}

.ws-cart-dropdown .ws-cart-item__actions .price {
  margin-bottom: 0;
}

.ws-cart-dropdown .ws-cart-item {
  padding: .5rem 0;
}

.ws-cart-dropdown .ws-cart-actions {
  padding: 1rem 0 0 0;
}

.ws-cart-dropdown .ws-cart-actions:after {
  content: '';
  display: block;
  clear: both;
}

.ws-cart-dropdown .ws-cart-total {
  padding: 10px;
  background-color: #F8F8F8;
}

.custom-input {
  width: auto;
  display: inline-block;
  max-width: 60px;
  margin-left: .5rem;
  padding: .375rem .75rem .375rem .75rem;
  color: #55595c;
  vertical-align: middle;
  background: #fff;
}

/*
 *	CART INFO
 */
.ws-info-box {
  padding: 1rem;
  background-color: #ddd;
}

.ws-info-box ul {
  padding-left: 0;
}

.ws-info-box li {
  list-style: none;
}

.ws-info-box .price {
  float: right;
}

.ws-info-box .table td {
  border-top: none;
}

.ws-info-box .table td:last-child {
  text-align: right;
}

.ws-info-box tfoot {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.ws-info-box tfoot tr:last-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* 
 *	CONTINUE
 */
.ws-continue {
  padding: 1rem 1rem .5rem;
  background-color: #ddd;
}

.ws-continue .btn {
  width: 100%;
}

.ws-continue__logos {
  display: block;
  margin-bottom: 0;
  padding: 0;
  font-size: 0;
}

.ws-continue__logos li {
  display: inline-block;
  margin-right: .5rem;
  margin-bottom: .5rem;
}

/*
 *	PROGRESS
 */
.ws-progress .nav {
  width: 100%;
  display: flex;
}

.ws-progress .nav-item {
  flex: 1 0 25%;
}

.ws-progress .nav-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  background-color: #ddd;
}

.ws-progress .nav-link.active {
  font-weight: bold;
  font-style: italic;
}

.ws-progress span.nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.ws-progress .nav-item:nth-child(2) .nav-link {
  background-color: #e5e5e5;
}

.ws-progress .nav-item:nth-child(3) .nav-link {
  background-color: #ececec;
}

.ws-progress .nav-item:nth-child(4) .nav-link {
  background-color: #f4f4f4;
}

/*
 *	PAYMENT OPTIONS
 */
.ws-payment-options {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1;
}

.ws-payment-options li {
  display: inline-block;
  width: 50px;
  margin: 0 3px 5px 0;
}

.ws-payment-options img {
  max-width: 100%;
}

.ws-pay-icon {
  width: 35px;
  vertical-align: middle;
}

@media (max-width: 575px) {
  .ws-progress .nav {
    flex-wrap: wrap;
  }
  .ws-progress .nav-item {
    flex: 1 0 100%;
  }
  .ws-progress .nav-link {
    padding: .5rem;
  }
}

/*
 *	Tijdelijke hacks en bugfixes
 */
/*
 *	CUSTOM RESPONSIVE CODE
 */
@media (max-width: 1033px) {
  .lang,
  .search-toggler {
    margin-left: 20px;
  }
}

@media (max-width: 1199px) {
  .banner__overlay {
    font-size: 1.2rem;
    /*.container {
			padding: 20px 20px 0;
		}*/
  }
  .banner__overlay .slider-quote {
    padding-top: 20px;
  }
  .banner__overlay .bordered {
    padding-bottom: 20px;
  }
  .banner__overlay .title {
    font-size: 2.2rem;
  }
  .banner__overlay--white .container {
    padding: 5px 10px 20px;
    /* same padding */
  }
  .carousel--casestudies .case {
    transform: none;
    position: relative;
    top: 0;
    left: 0;
  }
  .carousel--casestudies .carousel-control-prev,
  .carousel--casestudies .carousel-control-next {
    top: 15px;
    margin-top: 0;
  }
  .carousel--casestudies .carousel-control-prev {
    left: -15px;
  }
  .carousel--casestudies .carousel-control-next {
    right: 33.33%;
    right: calc(33.33% - 7px);
  }
  .content-banner__text .inner {
    padding: 30px 15px;
  }
}

@media (max-width: 991px) {
  .rich-content {
    padding: 0 40px;
  }
  .search-toggler,
  .search-form {
    display: none;
  }
  .navbar-toggleable-md .navbar-collapse {
    width: 100%;
    margin-left: 0;
  }
  .header {
    max-height: 100%;
    overflow-y: scroll;
  }
  .navbar-toggler {
    position: absolute;
    right: 0;
    top: 10px;
  }
  .lang {
    position: absolute;
    top: 18px;
    right: 110px;
  }
  .navbar-nav {
    text-align: center;
    margin-top: 10px;
    padding-top: 5px;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-nav .nav-item + .nav-item {
    margin-left: 0;
  }
  .navbar-nav .nav-item {
    padding: 3px 0;
  }
  .navbar-nav .nav-link {
    padding: 0 1rem;
  }
  .dropdown-menu {
    text-align: center;
  }
  .left-pad {
    padding-left: 0;
  }
  .right-pad {
    padding-right: 0;
  }
  .carousel--casestudies .carousel-control-next {
    right: -15px;
  }
  .profile-cta {
    padding: 0;
  }
  .blog-nav {
    padding: 5px 0;
    top: -55px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.03);
  }
  .blog-nav li {
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .rich-content {
    padding: 0 20px;
  }
  .banner {
    padding-bottom: 0;
  }
  .banner__overlay {
    position: static;
  }
  .banner__overlay .container {
    position: static;
  }
  .banner__overlay .container::after {
    left: 0;
    right: 0;
    width: auto;
  }
  .banner__overlay .title {
    font-size: 1.8rem;
  }
  .gradient-title {
    min-height: 70px;
    padding-left: 20px;
  }
  .gradient-title .title {
    font-size: 1.8rem;
  }
  .section {
    padding: 50px 0;
  }
  .carousel--casestudies {
    margin: 30px 0;
  }
  .banner__overlay .container {
    width: 100%;
  }
  .carousel--quotes .slider-quote {
    padding-left: 35px;
    padding-right: 35px;
  }
  .carousel--quotes .carousel-control-next,
  .carousel--quotes .carousel-control-prev {
    width: 30px;
  }
  .tetris-wrapper {
    display: block;
  }
  .tetris {
    margin-bottom: 30px;
    display: flex;
  }
  .tetris__text {
    padding: 10px 15px;
    position: static;
    width: 100%;
    text-align: left;
    font-size: 1.3rem;
  }
  .tetris__text .title {
    font-size: 1.3rem;
  }
  .tetris--alt .tetris__text {
    text-align: left;
  }
  .flex-direction-nav .flex-prev,
  .flex-direction-nav .flex-next {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .flex-direction-nav .flex-prev {
    left: 0 !important;
  }
  .flex-direction-nav .flex-next {
    right: 0 !important;
  }
  .blog--huge {
    background-color: #fff;
  }
  .blog--huge a:hover, .blog--huge a:focus {
    color: #fff;
  }
  .blog--huge a:hover .blog__text, .blog--huge a:focus .blog__text {
    color: #fff;
  }
  .blog--huge .blog__img::after {
    content: none;
  }
  .blog--huge .blog__text {
    z-index: 2;
    position: relative;
    background-image: none;
    color: #444444;
  }
  .blog-nav {
    position: static;
    margin-bottom: 30px;
    text-align: center;
  }
  .blog-nav li {
    margin: 4px 10px;
  }
  .content-banner__text {
    position: static;
  }
  .content-banner__text .container,
  .content-banner__text .inner {
    width: 100%;
  }
  .content-banner__text .container {
    padding: 0;
  }
  .content-banner__text .inner {
    padding: 15px;
  }
}

@media (max-width: 550px) {
  .blog-nav {
    background-color: transparent;
    text-align: right;
  }
  .blog-nav li {
    display: block;
    margin: 4px 0;
  }
  .banner__overlay .title {
    font-size: 1.4rem;
  }
  .banner__overlay .bordered {
    border-right: none;
  }
  .banner__overlay .btn {
    font-size: 1.1rem;
    padding: .25rem .5rem;
  }
}

@media (max-width: 400px) {
  body {
    padding-top: 50px;
  }
  .navbar-brand {
    width: 140px;
  }
  .navbar-toggler {
    height: 30px;
  }
  .lang {
    top: 13px;
  }
  .section {
    padding: 30px 0;
  }
  .pack__number {
    position: static;
    text-align: center;
    font-size: 72px;
    padding-top: 20px;
  }
  .pack__desc {
    padding: 20px;
  }
}

@media (max-width: 333px) {
  .navbar-toggler .text {
    display: none;
  }
  .lang {
    right: 63px;
  }
}

/*# sourceMappingURL=app.css.map */
